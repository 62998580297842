<script setup lang="ts">
interface Props {
  for: string;
  required?: boolean;
  className?: string;

  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
  required: false
});
</script>
<template>
  <label
    :class="[
      'mb-2 inline-block text-base leading-5 font-medium text-brand-dark-gray',
      className
    ]"
    :for="props.for"
  >
    <slot />
    <span v-if="props.required" class="text-brand-red-300">*</span>
  </label>
</template>
