<script setup lang="ts">
import { inject } from 'vue';
import { cva } from 'class-variance-authority';

defineOptions({
  inheritAttrs: false
});

const slots = useSlots();

interface Props {
  name?: string;
  id?: string;
  modelValue?: string | number;
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  ariaDescribedBy?: string;
  className?: string;
  inputClassName?: string;
  type?: string;

  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text'
});

const emit = defineEmits(['update:modelValue']);

const field = inject('field', props);

const localType = ref<string>(props.type);

const isPassword = computed<boolean>(() => {
  return props.type === 'password';
});

const passwordVisibilityIcon = computed(() => {
  return localType.value === 'text'
    ? 'fluent:eye-off-20-regular'
    : 'fluent:eye-20-regular';
});

function togglePassword() {
  localType.value = localType.value === 'password' ? 'text' : 'password';
}

const hasStartContent = computed<boolean>(() => {
  return !!slots.start;
});

const hasEndContent = computed<boolean>(() => {
  return !!slots.end || isPassword.value;
});

const labelClass = computed(() => {
  return cva(
    [
      'w-full flex items-center rounded-[5px] px-3 border has-[:focus]:border-blue-500 has-[:focus]:ring-4 has-[:focus]:ring-blue-100',
      props.className
    ],
    {
      variants: {
        invalid: {
          true: 'border-brand-red-300',
          false: 'border-brand-gray'
        },
        disabled: {
          true: 'bg-brand-light-gray',
          false: 'bg-white'
        }
      }
    }
  )({
    invalid: field.value?.invalid,
    disabled: props.disabled
  });
});

const inputClass = computed(() => {
  return cva(
    [
      'w-full py-3 text-base leading-5 outline-none placeholder:text-brand-gray disabled:bg-brand-light-gray',
      props.inputClassName
    ],
    {
      variants: {
        hasStartContent: {
          true: 'ml-2.5'
        },
        hasEndContent: {
          true: 'mr-2.5'
        }
      }
    }
  )({
    hasStartContent: hasStartContent.value,
    hasEndContent: hasEndContent.value
  });
});

const togglePasswordIconClass = computed(() => {
  return cva('text-brand-gray hover:text-brand-medium-gray', {
    variants: {
      disabled: {
        true: 'cursor-default pointer-events-none',
        false: 'cursor-pointer'
      }
    }
  })({
    disabled: props.disabled
  });
});
</script>

<template>
  <label :class="labelClass">
    <slot name="start" />
    <input
      v-bind="$attrs"
      :id="field.id"
      :name="field.name"
      :type="localType"
      :value="props.modelValue"
      :required="field.required"
      :disabled="disabled"
      :class="inputClass"
      @input="($event) => emit('update:modelValue', $event.target.value)"
    />
    <template v-if="isPassword">
      <Icon
        :class="togglePasswordIconClass"
        :name="passwordVisibilityIcon"
        size="28"
        @click="togglePassword"
      />
    </template>
    <slot name="end" />
  </label>
</template>
