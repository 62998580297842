<script setup lang="ts">
import Label from '@/components/common/form/Label.vue';
import ErrorMessage from '@/components/common/form/ErrorMessage.vue';
import HelpMessage from '@/components/common/form/HelpMessage.vue';
import { computed, type ComputedRef, provide } from 'vue';
import { snakeCase } from 'change-case';

interface Props {
  name: string;
  id?: string;
  label?: string;
  required?: boolean;
  error?: string;
  help?: string;
  className?: string;

  [x: string]: any;
}

type Provides = Props & {
  invalid?: boolean;
  ariaDescribedBy?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  id: (rawProps) => snakeCase(rawProps.name || '')
});

const ariaDescribedBy = computed(() => {
  return props.help ? `help-${props.id}` : null;
});

const provides = computed<Provides>(() => {
  return {
    ...props,
    name: props.name,
    invalid: !!props.error,
    ariaDescribedBy: ariaDescribedBy.value
  };
});

provide<ComputedRef<Provides>>('field', provides);
</script>

<template>
  <div :class="['flex flex-col mb-4', className]">
    <slot name="label" v-bind="props">
      <Label v-if="props.label" :for="props.id" :required="props.required">{{
        props.label
      }}</Label>
    </slot>

    <div class="flex flex-col grow">
      <slot v-bind="props" />

      <ErrorMessage v-if="props.error">
        {{ props.error }}
      </ErrorMessage>

      <HelpMessage v-if="props.help" :id="ariaDescribedBy">
        {{ props.help }}
      </HelpMessage>
    </div>
  </div>
</template>
